import { isEmpty, isNil } from 'lodash-es';
import { Observable, OperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

export function filterNotNil<T>(): (source: Observable<T | undefined>) => Observable<T> {
  return (source: Observable<any>) => source.pipe(
    filter((value?: T): value is T => !isNil(value))
  );
}

export function filterNotEmpty<T>(): (source: Observable<T | undefined>) => Observable<T> {
  return (source: Observable<any>) => source.pipe(
    filter((value?: T): value is T => !isEmpty(value))
  );
}

export function filterTruthy(): (source: Observable<boolean | undefined>) => Observable<boolean> {
  return (source: Observable<any>) => source.pipe(
    filter((value?: boolean): value is boolean => !!value)
  );
}

export function applyConditionalOperator<T>(condition: boolean, op1: OperatorFunction<T, T>, op2: OperatorFunction<T, T>): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>) => condition ? source.pipe(op1) : source.pipe(op2);
}
