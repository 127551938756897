import { HttpErrorResponse } from '@angular/common/http';
import { noop } from 'lodash-es';
import { Observable, retry, throwError, timer } from 'rxjs';

export interface RetryStrategyParams {
  maxRetryAttempts?: number;
  scalingDuration?: number;
  excludedStatusCodes?: number[];
  // todo retry 발생 시 에러로그를 console 에 찍도록 유도 및 중복된 아이템 sentry 로 보고 되도록 작업 > 추후 제거 필요
  // https://app.asana.com/0/692598029547572/1204164547327048
  errorCallback?: (error: HttpErrorResponse) => void;
}

// @see https://www.learnrxjs.io/operators/error_handling/retrywhen.html
// TODO 타입을 살릴 수 있는 방법?
export function retryCountExcludedStatusCodes({
                                                maxRetryAttempts = 2,
                                                scalingDuration = 100,
                                                excludedStatusCodes = [],
                                                errorCallback = noop
                                              }: RetryStrategyParams = {}): (source: Observable<any>) => Observable<any> {
  return (source: Observable<any>) => source.pipe(
    retry({
      count: maxRetryAttempts,
      delay: (error, retryCount) => {
        const retryAttempt = retryCount + 1;
        if (excludedStatusCodes.some(e => e === error.status)) {
          return throwError(() => error);
        }
        // todo retry 발생 시 에러로그를 console 에 찍도록 유도 및 중복된 아이템 sentry 로 보고 되도록 작업 > 추후 제거 필요
        // https://app.asana.com/0/692598029547572/1204164547327048
        errorCallback(error);
        return timer(retryAttempt * scalingDuration);
      }
    })
  );
}
