import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

// TODO error key값 분리 필요 - key 값에 따른 메시지 처리에 필요함
// @dynamic
export class BwValidator {
  public static domain(): ValidatorFn {
    return Validators.pattern(this.domainPattern());
  }

  /**
   * 이메일 validator!
   */
  static email(): ValidatorFn {
    return Validators.pattern(this.emailPattern());
  }

  /**
   * 핸드폰 번호 vaildator!
   */
  static mobile(): ValidatorFn {
    return Validators.pattern(this.mobilePattern());
  }

  /**
   * only num validator!
   */
  static num(): ValidatorFn {
    return Validators.pattern(this.numPattern());
  }

  /**
   * only 영어 validator!
   */
  static eng(): ValidatorFn {
    return Validators.pattern(this.engPattern());
  }

  /**
   * 긴 생년월일 validator! - ex. 19870604 or 1987-06-04
   */
  static birthdayLong(control: UntypedFormControl): ValidationErrors | null {
    if (new RegExp(BwValidator.datePattern()).test(control.value)) {
      return {
        birthday: true
      };
    }
    return null;
  }

  /**
   * 짧은 생년월일 validator! - ex. 870604
   */
  static birthdayShort(control: UntypedFormControl): ValidationErrors | null {
    if (new RegExp(BwValidator.dateShortPattern()).test(control.value)) {
      return {
        birthday: true
      };
    }
    return null;
  }

  /**
   * 날짜 validator!
   */
  static date(): ValidatorFn {
    return Validators.pattern(this.datePattern());
  }

  /*
   * 여권번호 Vaildators
   * */
  static passport(): ValidatorFn {
    return Validators.pattern(this.passportNumberPattern());
  }


  static cardNo(): ValidatorFn {
    return Validators.pattern(this.cardNoPattern());
  }

  static cardPeriodMonth(): ValidatorFn {
    return Validators.pattern(this.cardPeriodMonthPattern());
  }

  static cardPeriodYear(): ValidatorFn {
    return Validators.pattern(this.cardPeriodYearPattern());
  }

  static contractCode(): ValidatorFn {
    return Validators.pattern(this.contractCodePattern());
  }

  /**
   * 주민등록 번호 뒤 7자리
   */
  static regNo(control: UntypedFormControl): ValidationErrors | null {
    if (new RegExp(BwValidator.regNoPattern()).test(control.value)) {
      return {
        regNo: true
      };
    }
    return null;
  }


  /**
   * 사업자번호 10자리
   */
  static bizNo(): ValidatorFn {
    return Validators.pattern(this.bizNoPattern());
  }

  static excludedSpecialWord(): ValidatorFn {
    return Validators.pattern(this.excludedSpecialPattern());
  }

  static excludedKor(): ValidatorFn {
    return Validators.pattern(this.excludedKorPattern());
  }

  static cardPeriodMonthPattern(): string {
    return '([0][1-9])|([1][0-2])';
  }

  static cardPeriodYearPattern(): string {
    return '([1-2][0-9])';
  }

  static cardNoPattern(): string {
    return '[3,4,5,9][0-9]{15}';
    // return '(5[1-5]\d{14})|(4\d{12})(\d{3}?)|3[47]\d{13}|(6011\d{12})';
  }

  static bizNoPattern(): string {
    return '^([0-9]{10})$';
  }

  // 주민번호 뒷자리
  static regNoPattern(): string {
    // return '(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))[1-6]';
    return '[1-4][0-9]{6}'; // 뒷자리
  }

  // 이멜 pattern
  static emailPattern(): string {
    return '[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[\.][a-zA-Z0-9-.]+';
  }

  // 핸드폰 번호 pattern
  static mobilePattern(): string {
    // return '^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$';
    // return '^(?:(010-\d{4})|(01[1|6|7|8|9]-\d{3,4}))-(\d{4})$';
    // return '01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$';
    // return '[0-9]{10,16}'
    // return '(010[0-9]{8})|(01[1|6|7|8|9][0-9]{7,8})';
    return '^(01[016789]|999)[0-9]{7,8}$';
  }

  static numPattern(): string {
    return '[0-9]+';
  }

  // 영어 패턴
  static engPattern(): string {
    return '[A-Za-z]*';
  }

  // 날짜 패턴 (ex. 19870604 or 1987-06-04)
  static datePattern(): string {
    // return '^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1])$';
    return '((19|20)[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[0-1]))' + '|' +
      '((19|20)[0-9]{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1]))';
  }

  // 날짜 줄임 패턴(ex. 870604)
  static dateShortPattern(): string {
    return '^(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))$';
  }

  // 여권 번호 패턴
  static passportNumberPattern(): string {
    return '[a-zA-Z0-9]+';
  }

  static excludedSpecialPattern(): string {
    return '[a-zA-Z0-9\\s]+';
  }

  static excludedKorPattern(): string {
    return '[^가-힣ㄱ-ㅎㅏ-ㅣ]+';
  }

  static domainPattern(): string {
    return '(?:(?:(?:[a-zA-z\\-]+)\\:\\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9-\\.]){1,61}[a-zA-Z0-9](?:\\.[a-zA-Z]{2,})+|\\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\\]|(?:(?:[0-9]{1,3})(?:\\.[0-9]{1,3}){3}))(?:\\:[0-9]{1,5})?';
  }

  // 프로젝트 코드 패턴 (영문, 숫자, 하이푼, 언더바)
  static contractCodePattern(): string {
    return '^$|^[\\w-]+$';
  }

  static nospace(control: UntypedFormControl): ValidationErrors | null {
    if (/\s/.test(control.value)) {
      return { nospace: true };
    }
    return null;
  }

  static noSameCharacter(count: number): ValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value || '';
      let prevChar: string | null = null;
      let findCount = 1;
      for (const currentChar of value) {
        if (prevChar === currentChar) {
          findCount++;

          if (findCount >= count) {
            return { noSameCharacter: { count } };
          }
        } else {
          findCount = 1;
          prevChar = currentChar;
        }
      }
      return null;
    };
  }

  static MatchPassword(passwordFieldName: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (control && control.parent) {
        const password = control.parent.get(passwordFieldName)?.value;
        const confirmPassword = control.value;
        if (password !== confirmPassword) {
          return { MatchPassword: true };
        }
      }
      return null;
    };
  }

  public static onlyKorEngNum(): ValidatorFn {
    return Validators.pattern('[가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9]+');
  }

  public static emailId(control: UntypedFormControl): ValidationErrors | null {
    if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+$/.test(control.value)) {
      return { emailId: true };
    }
    return null;
  }

  public static accountNumber(control: UntypedFormControl): ValidationErrors | null {
    const value = control.value || '';

    if (!/^[0-9]{8,15}$/.test(value.replace(/-/g, ''))) {
      return { accountNumber: true };
    }

    return null;
  }

  static unit(unit: number): ValidatorFn {
    return (control: AbstractControl) => {
      const value = parseInt(control.value, 10);
      if (isNaN(value) || value % unit !== 0) {
        return { unit: { unit } };
      }
      return null;
    };
  }

  // 공백 제외한 문자열 required Validator
  public static validCharacterRequired(control: UntypedFormControl): ValidationErrors | null {
    const value = control.value?.trim();
    if (!value) {
      return { validCharacterRequired: true };
    }
    return null;
  }
}
