import { Injector } from '@angular/core';

// @dynamic
export class AppInjector {
  private static instance: AppInjector;
  private injector?: Injector;

  private constructor() {
  }

  public static getInstance(): AppInjector {
    if (!AppInjector.instance) {
      AppInjector.instance = new AppInjector();
    }

    return AppInjector.instance;
  }

  public setInjector(injector: Injector): void {
    this.injector = injector;
  }

  public getInjector(): Injector | undefined {
    return this.injector;
  }
}
